import { createContext, useContext } from 'react';
import { useAuth } from './AuthContext';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children, data }) => {
  const { user } = useAuth();

  const actionsLeft = data.remainingActions;
  const remainingReinforcement = data.remainingReinforcement;
  const userReinforcement = data.userReinforcement;
  const conquish = data.gameSession.board.filter(
    (cell) => cell.planetId === user.planetId
  ).length;

  const getSelectedCell = (selectedCellId) => {
    return data.gameSession.board.find((cell) => cell.id === selectedCellId);
  };

  return (
    <GameContext.Provider
      value={{
        board: data.gameSession.board,
        actionsLeft,
        remainingReinforcement,
        userReinforcement,
        conquish,
        gameTurn: data.gameSession.gameTurn,
        getSelectedCell,
        version: data.gameSession.version,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
