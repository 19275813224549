import React from 'react';
import '../styles/header.css'; // Assurez-vous de créer ce fichier CSS
import { useAuth } from '../context/AuthContext';
import { Tooltip } from 'react-tooltip';
import { PLANETS } from '../constants';
import { useGame } from '../context/GameContext';

const Header = ({ onHelpClick, onReportClick }) => {
  const { user } = useAuth();
  const { conquish, actionsLeft, remainingReinforcement, gameTurn } = useGame();

  return (
    <header className='header'>
      {/* Conteneur gauche */}
      <Tooltip
        anchorSelect='.left-container'
        place='bottom'
        className='tooltip tooltip-header'
      />
      <div
        className='left-container'
        data-tooltip-content='Actions disponibles pour ce tour'
      >
        <img src='/action-cross.svg' alt='Left Icon' className='left-image' />
        <div className='left-box'>
          <span className='left-text'>{actionsLeft}</span>
        </div>
      </div>

      <div className='left-icons'>
        <div className='icon' onClick={onReportClick}>
          <img src='/history-reports.svg' alt='Icon 1' />
        </div>
        <div className='icon' onClick={onHelpClick} type='button'>
          <img src='/help-icon.svg' alt='Help' />
        </div>
      </div>

      {/* Conteneur central */}
      <div className='center-container'>
        <div className='center-box'>
          <div className='center-main-text'>TOUR {gameTurn}</div>
          <div
            className='center-sub-text'
            style={{
              color: PLANETS[user.planetId].color,
            }}
          >
            {PLANETS[user.planetId].name}
          </div>
        </div>
      </div>

      <Tooltip
        anchorSelect='.central-container'
        place='bottom'
        className='tooltip tooltip-header'
      />
      <div
        className='central-container'
        data-tooltip-content='Renforcement restant pour la planète'
      >
        <img
          src='/reinforce-left.svg'
          alt='Right Icon'
          className='right-image'
        />
        <div className='right-box'>
          <span className='left-text'>{remainingReinforcement}</span>
        </div>
      </div>

      {/* Conteneur droit */}
      <Tooltip
        anchorSelect='.right-container'
        place='bottom'
        className='tooltip tooltip-header'
      />
      <div
        className='right-container'
        data-tooltip-content='Cellules contrôlées par la planète'
      >
        <img src='/cells.svg' alt='Right Icon' className='right-image' />
        <div className='right-box'>
          <span className='right-text'>{conquish}</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
