export const PLANETS = {
  1: {
    id: 1,
    name: 'Neutre',
    color: '#C1D1ED',
  },
  2: {
    id: 2,
    name: 'Donut Factory',
    color: '#E82E31',
  },
  3: {
    id: 3,
    name: 'Duck Invaders',
    color: '#47B68B',
  },
  4: {
    id: 4,
    name: 'Schizo Cats',
    color: '#4C58A7',
  },
  5: {
    id: 5,
    name: 'Racoons Of Asgard',
    color: '#EDD519',
  },
};

export const ACTION = {
  NOTHING: 0,
  SELECTED: 1,
  REINFORCED: 2,
  PREPARE_MOVE: 3,
  MOVE: 4,
};
