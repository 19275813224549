import React from 'react';
import CloseButton from './CloseButton';

const ErrorPopup = ({ onClose, error }) => {
  return (
    <div className='popup'>
      <CloseButton onClose={onClose} />
      <div className='popup-content'>
        <h2>{error}</h2>
      </div>
    </div>
  );
};

export default ErrorPopup;
