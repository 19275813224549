import React from 'react';
import '../../styles/popup.css'; // Assurez-vous d'ajouter un fichier CSS pour les styles
import CloseButton from './CloseButton';
import { useGame } from '../../context/GameContext';

const Popup = ({ onClose, onReinforce, onMove, cell }) => {
  const { remainingReinforcement } = useGame();
  return (
    <div className='popup'>
      <CloseButton onClose={onClose} />

      <div className='popup-content'>
        <p>
          Renforcement : Choisir un nombre d'unités à rajouter sur la cellule.
          Ce nombre sera décompté du nombre d'unités total disponible ce tour
          pour la planète.
        </p>
        <p>
          Déplacement : Choisir le nombre d'unités à déplacer, et la cellule de
          destination.
        </p>
      </div>
      <div className='popup-buttons'>
        <button
          className='action-btn'
          onClick={onReinforce}
          disabled={remainingReinforcement <= 0}
        >
          Renforcement
        </button>
        <button
          className='action-btn'
          onClick={onMove}
          disabled={cell.units <= 1}
        >
          Déplacement
        </button>
      </div>
    </div>
  );
};

export default Popup;
