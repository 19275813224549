import axios from 'axios';

export const loginRequest = async (token) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/login`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
};

export const googleAuth = async (code) => {
  const tokens = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/google`,
    { code }
  );

  return tokens;
};
