import axios from 'axios';

export const getGameInfos = async () => {
  const token = localStorage.getItem('token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/gameinfos`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const fetchIsValidCells = async (cellId) => {
  const token = localStorage.getItem('token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/getValidCells/${cellId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getTurnHistory = async () => {
  const token = localStorage.getItem('token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/getTurnHistory`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const goToNextTurn = async () => {
  const token = localStorage.getItem('token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/nextTurn`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
export const reinforcedCell = async ({ cellId, units, version }) => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/reinforcement`,
    {
      cellId,
      units,
      version,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return response.data;
};

export const move = async ({ cellFromId, cellToId, units, version }) => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/move`,
    {
      cellFromId,
      cellToId,
      units,
      version,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return response.data;
};
