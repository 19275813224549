import { useQuery, useQueryClient } from '@tanstack/react-query';
import Board from './Board';
import { getGameInfos, goToNextTurn } from '../api/game';
import Header from './Header';
import { GameProvider } from '../context/GameContext';
import { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import NextTurnButton from './NextTurnButton';

const Home = () => {
  const { user } = useAuth();
  const { data, isLoading, error } = useQuery({
    queryKey: ['gameInfo'],
    queryFn: () => getGameInfos(),
  });
  const queryCLient = useQueryClient();

  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [isRapportPopupOpen, setIsRapportPopupOpen] = useState(false);
  const [initalRapport, setInitalRapport] = useState(null);

  useEffect(() => {
    const isNotFirstTime = localStorage.getItem('isNotFirstTime');
    if (!isNotFirstTime) {
      localStorage.setItem('isNotFirstTime', true);
      setIsInfoPopupOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    const lastTurnPlayed = localStorage.getItem('lastTurnPlayed');

    console.log('last turnb', lastTurnPlayed);

    if (!lastTurnPlayed) {
      localStorage.setItem('lastTurnPlayed', data.gameSession.gameTurn);
    } else {
      if (parseInt(lastTurnPlayed) !== parseInt(data.gameSession.gameTurn)) {
        setIsRapportPopupOpen(true);
        localStorage.setItem('lastTurnPlayed', data.gameSession.gameTurn);
        setInitalRapport(lastTurnPlayed);
      }
    }
  }, [data, initalRapport]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading game info</p>;

  return (
    <div className='app'>
      <GameProvider data={data}>
        <Header
          onHelpClick={() => setIsInfoPopupOpen(true)}
          onReportClick={() =>
            data?.gameSession.gameTurn > 1 ? setIsRapportPopupOpen(true) : null
          }
        />
        <Board
          isInfoPopupOpen={isInfoPopupOpen}
          setIsInfoPopupOpen={setIsInfoPopupOpen}
          isRapportPopupOpen={isRapportPopupOpen}
          setIsRapportPopupOpen={setIsRapportPopupOpen}
          initalRapport={initalRapport}
        />
        {user.isAdmin && (
          <NextTurnButton
            onClick={async () => {
              try {
                await goToNextTurn();
                queryCLient.invalidateQueries({ queryKey: ['gameInfo'] });
              } catch {
                console.error('NOT AUTHORIZED');
              }
            }}
          />
        )}
      </GameProvider>
    </div>
  );
};

export default Home;
