import React, { useEffect, useState } from 'react';
import '../../styles/popup.css'; // Assurez-vous d'ajouter un fichier CSS pour les styles
import CloseButton from './CloseButton';
import { useQuery } from '@tanstack/react-query';
import { getTurnHistory } from '../../api/game';
import { PLANETS } from '../../constants';
import { useAuth } from '../../context/AuthContext';

const RapportPopup = ({ onClose, initalRapport }) => {
  const { user } = useAuth();
  const { data } = useQuery({
    queryKey: ['turnHistory'],
    queryFn: () => getTurnHistory(),
  });

  const [rapport, setRapport] = useState(null);

  useEffect(() => {
    if (initalRapport && data) {
      const report = data.find(
        (history) => parseInt(history.gameTurn) === parseInt(initalRapport)
      );
      setRapport(report);
    }
  }, [initalRapport, data]);

  if (!data) {
    return;
  }

  return (
    <div className='popup rapportPopup'>
      <CloseButton onClose={onClose} />

      <div className='popup-header'>
        {rapport ? (
          <h2>RAPPORT DU TOUR {rapport.gameTurn}</h2>
        ) : (
          <h2>RAPPORTS</h2>
        )}
        <h3
          style={{
            color: PLANETS[user.planetId].color,
          }}
        >
          {' '}
          {PLANETS[user.planetId].name.toUpperCase()}
        </h3>
      </div>
      <div className='popup-content report-content'>
        {!rapport &&
          data.map((history) => {
            return (
              <h4
                key={history.id}
                onClick={() => setRapport(history)}
                type='button'
              >
                RAPPORT DU TOUR {history.gameTurn}
              </h4>
            );
          })}

        {rapport && (
          <>
            <div className='report-item'>
              <img src='/unit-lost-icon.svg' alt='Icon units lost' />
              <p>Unités perdues: {rapport.unitsLost}</p>
            </div>
            <div className='report-item'>
              <img src='/nb-move-icon.svg' alt='Icon number movement' />
              <p>Déplacement effectués : {rapport.nbMove}</p>
            </div>
            <div className='report-item'>
              <img src='/cell-conquish-icon.svg' alt='Icon cells win' />
              <p>Cellules conquises : {rapport.cellConquish}</p>
            </div>
            <div className='report-item'>
              <img src='/cell-lost-icon.svg' alt='Icon cells lost' />
              <p>Cellules concédées : {rapport.cellLost}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RapportPopup;
