import { useGoogleLogin } from '@react-oauth/google';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/login.css';

const Login = () => {
  const { user, login } = useAuth();

  const handleGoogleResponse = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      await login(access_token);
    },
  });

  if (user) {
    return <Navigate replace to='/' />;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
      }}
    >
      <div id='container'>
        <img src='/cells-login.svg' alt='Login Button' id='cells' />
        <img src='/home-virus.svg' alt='Login Button' id='virus' />
        <img src='/hometitle.svg' alt='Login Button' id='title' />
        <div
          style={{ zIndex: '99' }}
          onClick={() => handleGoogleResponse()}
          role='button'
        >
          <img src='/homelogin.svg' alt='Login Button' id='login' />
        </div>
      </div>
    </div>
  );
};

export default Login;
