import React, { useRef } from 'react';
import CloseButton from './CloseButton';
import { ACTION } from '../../constants';
import { useGame } from '../../context/GameContext';

const ACTIONS_LABELS = {
  [ACTION.REINFORCED]: {
    label: "Choisir le nombre d'unités pour renforcer la cellule.",
    button: 'Renforcer',
  },
  [ACTION.PREPARE_MOVE]: {
    label: 'Déplacer des unités',
    button: 'Déplacer',
  },
};

const NumberPopup = ({ onClose, handleAction, action, cell }) => {
  const { userReinforcement } = useGame();
  const unitRef = useRef(null);
  let minNumber = 0;
  let maxNumber = 0;
  let defaultNumber = 0;

  if (action === ACTION.REINFORCED) {
    minNumber = 1;
    maxNumber = userReinforcement;
    defaultNumber = userReinforcement;
  }
  if (action === ACTION.PREPARE_MOVE) {
    minNumber = 1;
    maxNumber = cell.units - 1;
    defaultNumber = Math.floor(cell.units / 2);
  }

  return (
    <div className='popup number-popup'>
      <CloseButton onClose={onClose} />
      <div className='popup-content'>
        <h2>{ACTIONS_LABELS[action].label}</h2>
        <div className='popup-buttons'>
          <input
            type='number'
            className='number-input'
            min={minNumber}
            max={maxNumber}
            ref={unitRef}
            onChange={(e) => {
              const value = e.target.value;
              if (value <= 0) {
                unitRef.current.value = minNumber;
              } else if (value > maxNumber) {
                unitRef.current.value = maxNumber;
              }
            }}
            defaultValue={defaultNumber}
          />
          <button
            className='action-btn'
            onClick={() => {
              handleAction(unitRef.current.value);
            }}
          >
            {ACTIONS_LABELS[action].button}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberPopup;
