import React from 'react';
import '../../styles/popup.css'; // Assurez-vous d'ajouter un fichier CSS pour les styles
import CloseButton from './CloseButton';

const content = {
  title: 'Règles du jeu',
  subtitle: 'Bonjour astronaute, et bienvenue dans Cells Wars !',
  sections: [
    {
      title: 'Le but du jeu',
      text: 'Chaque planète doit posséder un maximum de cases à la fin des 10 tours. Les cases à la couleur de ta planète sont celles que ton équipe possède. Les cases bleu clair sont des cases neutres, et les autres cases de couleur sont possédées par les autres planètes.',
    },
    {
      title: 'Actions par tour',
      text: (
        <>
          Chaque joueur peut utiliser 5 actions par tour. Faire un renforcement
          ou un déplacement coûte une de ces actions.
          <br />
          <br />
          1/ Renforcement <br />
          À chaque tour tu peux choisir de "renforcer" une ou plusieurs cellules
          déjà possédée par ta planète avec des unités. Ce nombre d'unités à
          renforcer est ensuite partagé par toute la planète. Les autres
          astronautes peuvent ensuite déplacer ces unités par exemple. Le nombre
          d'unités pour le renforcement est renouvelé à chaque tour. <br />
          <br />
          2/ Déplacement <br />
          Tu peux à chaque tour déplacer les unités possédées par ta planète
          d'une cellule vers une autre. Coordonne-toi bien avec les autres
          astronautes de ta planète !,
          <br />
        </>
      ),
    },
    {
      title: 'Contrôle des cases',
      text: "Si la case vers laquelle tu te déplaces est neutre, tu en prendras le contrôle. Si la case vers laquelle tu te déplaces est une case possédée par une autre planète, alors il y a bagarre ! Le nombre d'unités que tu déplaces s'opposera aux unités déjà présentes sur la case. La résolution de la 'bagarre' est une simple soustraction. La planète qui a le plus d'unités à la fin contrôlera la case.",
    },
  ],
};

const InfoPopup = ({ onClose }) => {
  const { title, subtitle, sections } = content;

  return (
    <div className='popup infoPopup'>
      <CloseButton onClose={onClose} />

      <div className='popup-content'>
        {/* Titre principal */}
        <h2>{title}</h2>

        {/* Sous-titre */}
        <p
          style={{
            fontSize: '12px',
            fontWeight: 'normal',
            textAlign: 'center',
          }}
        >
          {subtitle}
        </p>

        {/* Sections */}
        {sections.map((section, index) => (
          <div key={index} style={{ textAlign: 'left' }}>
            <h3
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#000',
                marginBottom: '5px',
              }}
            >
              {section.title}
            </h3>
            <p
              style={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#000',
                margin: 0,
                textAlign: 'left',
              }}
            >
              {section.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoPopup;
