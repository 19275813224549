import React from 'react';
import '../styles/nextTurnButton.css'; // Fichier CSS spécifique au bouton

const NextTurnButton = ({ onClick }) => {
  return (
    <button className='next-turn-button' onClick={onClick}>
      TOUR SUIVANT
    </button>
  );
};

export default NextTurnButton;
